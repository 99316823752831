const pora = {
  "Base/neutralPrimary": "#FFF",
  "Base/neutralSecondary": "#F1F7F8",
  "Base/neutralActive": "#E1F3F6",
  "Base/neutralDisabled": "#F2F5F7",

  "Base/accentPrimary": "#00C0EB",
  "Base/accentActive": "#00AFD6",
  "Base/accentFocused": "#0CA0C2",

  // colors are needed for compatability with lovi
  "Base/accentSecondary": "#00C0EB",
  "Base/accentSecondaryFocused": "#00AFD6",
  "Base/accentSecondaryBg": "#F1F7F8",
  // ---

  "Base/basePrimary": "#0C2330",
  "Base/baseSecondary": "#73879D",
  "Base/baseActive": "#1F3C4D",
  "Base/baseDisabled": "#ABBBCC",

  "Other/New": "rgb(245, 19, 97)",
  "Other/NewBg": "rgb(245 19 97 / 10%)",
  "Other/Green": "#45B87C",
  "Other/Green/80": "rgba(69, 184, 124, 0.8)",
  "Other/GreenDark": "#1A854D",
  "Other/Warning": "#FBA704",
  "Other/Error": "#F84018",
  "Other/Success": "#19B800",
  "Other/Info": "#2D71F9",
  "Other/Info/80": "rgba(97, 111, 238, 0.8)",

  "Utils/screenBgColor": "#FFF",
  accentColor: "var(--chakra-colors-Base\\/accentPrimary)",
}

const lovi = {
  "Base/basePrimary": "rgb(41 40 36)",
  "Base/baseSecondary": "rgb(41 40 36 / 50%)",
  "Base/baseTertiary": "rgba(41 40 36 / 12%)",
  "Base/baseDisabled": "rgb(41 40 36 / 30%)",

  "Base/neutralPrimary": "#FFF",
  "Base/neutralSecondary": "#F6F5F4",
  "Base/neutralDisabled": "rgb(0 0 0 / 5%)",
  "Base/neutralActive": "#FDF0EF",

  "Base/accentPrimary": "#5263FF",
  "Base/accentPrimary/90": "#5263FFE5",
  "Base/accentPrimaryFocused": "#4755D4",
  "Base/accentPrimaryBg": "#EEEFFF",
  "Base/accentPrimaryBg2": "rgba(82, 99, 255, 12%)",
  // Same colors from Pora
  "Base/accentActive": "#EC79A2",
  "Base/accentFocused": "#BE437B",

  "Base/accentSecondary": "#EC79A2",
  "Base/accentSecondaryFocused": "#BE437B",
  "Base/accentSecondaryBg": "#FDF0EF",
  "Base/accentSecondaryBg2": "rgb(236 121 162 / 25%)",

  "Other/New": "#DC689C",
  "Other/New/20": "#DC689C33",
  "Other/NewBg": "#FDF0EF",
  "Other/Green": "#45B87C",
  "Other/Green/20": "#45B87C33",
  "Other/Green/80": "rgba(69, 184, 124, 0.8)",
  "Other/GreenDark": "#1A854D",
  "Other/Warning": "#FF991F",
  "Other/Error": "#EF6142",
  "Other/Success": "#19B800",
  "Other/Info": "#616FEE",
  "Other/Info/20": "#616FEE33",
  "Other/Info/80": "rgba(97, 111, 238, 0.8)",

  "Utils/screenBgColor": "#F6F5F4",
  accentColor: "var(--chakra-colors-Base\\/accentSecondary)",
}

const commonColors = {
  extra: {
    WhiteAlpha0: "#FFF0",
    WhiteAlpha50: "rgba(255, 255, 255, 0.5)",
    BlackAlpha0: "#0000",
  },
  custom: {
    reviewFb: "#F6F7F9",
  },
}

const themeColors = (theme: Theme) => (theme === "lovi" ? lovi : pora)
export const getColors = (theme: Theme) => ({ ...commonColors, ...themeColors(theme) })
