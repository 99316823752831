/* eslint-disable */
import { AttributedString, Color } from "./presentation";

export const protobufPackage = "skin_id";

export interface SummaryListItem {
  /** @deprecated */
  image: string;
  title: string;
  description: string;
  /** @deprecated */
  badge: string;
}

export interface WebScanSkinIDScreen {
  title: AttributedString | undefined;
  subtitle: AttributedString | undefined;
  summary: WebScanSkinIDScreen_Summary | undefined;
  footer_button_title: string;
  footer_button_quiz_id: string;
}

export interface WebScanSkinIDScreen_Summary {
  items: WebScanSkinIDScreen_Summary_Item[];
}

export interface WebScanSkinIDScreen_Summary_Item {
  id: string;
  title: string;
  value: WebScanSkinIDScreen_Summary_Item_Value | undefined;
}

export interface WebScanSkinIDScreen_Summary_Item_Value {
  value?: { $case: "answer"; answer: WebScanSkinIDScreen_Summary_Item_Value_Answer } | {
    $case: "web_scan";
    web_scan: WebScanSkinIDScreen_Summary_Item_Value_WebScan;
  };
}

export interface WebScanSkinIDScreen_Summary_Item_Value_Answer {
  answer: string;
  is_secondary: boolean;
  /** The id of interview quiz that should be opened when the item is clicked. */
  quiz_id: string;
}

export interface WebScanSkinIDScreen_Summary_Item_Value_WebScan {
  content?: { $case: "resource"; resource: string } | { $case: "scan_id"; scan_id: string };
}

export interface SkinIDScreen {
  scan_report_id: string;
  title: AttributedString | undefined;
  summary_items: SummaryListItem[];
  visualizations: IssueSubtypeVisualization[];
  view_report_button_title: string;
  bottom_button_title: string;
}

export interface ScanReportScreen {
  scan_report_id: string;
  date: string;
  sub_title: string;
  title: string;
  displayed_photo_angles: ScanReportScreen_PhotoAngle[];
  tabs: ScanReportScreen_Tab[];
  /** The id of the tab that should be selected by default */
  selected_tab_id: string;
  /**
   * The map of all of the issues from the scan report.
   * The key is the issue id.
   */
  issues_by_id: { [key: string]: ScanReportScreen_Issue };
  /**
   * Use `Issue.frontal_visualization` instead.
   *
   * @deprecated
   */
  DEPRECATED_frontal_issues: ScanReportScreen_Issue[];
  /**
   * Use `Issue.left_visualization` instead.
   *
   * @deprecated
   */
  DEPRECATED_left_issues: ScanReportScreen_Issue[];
  /**
   * Use `Issue.right_visualization` instead.
   *
   * @deprecated
   */
  DEPRECATED_right_issues: ScanReportScreen_Issue[];
}

/** User photo angles that are displayed in the Scan Report screen. */
export enum ScanReportScreen_PhotoAngle {
  PHOTO_ANGLE_NONE = "PHOTO_ANGLE_NONE",
  PHOTO_ANGLE_FRONTAL = "PHOTO_ANGLE_FRONTAL",
  PHOTO_ANGLE_LEFT = "PHOTO_ANGLE_LEFT",
  PHOTO_ANGLE_RIGHT = "PHOTO_ANGLE_RIGHT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function scanReportScreen_PhotoAngleFromJSON(object: any): ScanReportScreen_PhotoAngle {
  switch (object) {
    case 0:
    case "PHOTO_ANGLE_NONE":
      return ScanReportScreen_PhotoAngle.PHOTO_ANGLE_NONE;
    case 1:
    case "PHOTO_ANGLE_FRONTAL":
      return ScanReportScreen_PhotoAngle.PHOTO_ANGLE_FRONTAL;
    case 2:
    case "PHOTO_ANGLE_LEFT":
      return ScanReportScreen_PhotoAngle.PHOTO_ANGLE_LEFT;
    case 3:
    case "PHOTO_ANGLE_RIGHT":
      return ScanReportScreen_PhotoAngle.PHOTO_ANGLE_RIGHT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ScanReportScreen_PhotoAngle.UNRECOGNIZED;
  }
}

export function scanReportScreen_PhotoAngleToJSON(object: ScanReportScreen_PhotoAngle): string {
  switch (object) {
    case ScanReportScreen_PhotoAngle.PHOTO_ANGLE_NONE:
      return "PHOTO_ANGLE_NONE";
    case ScanReportScreen_PhotoAngle.PHOTO_ANGLE_FRONTAL:
      return "PHOTO_ANGLE_FRONTAL";
    case ScanReportScreen_PhotoAngle.PHOTO_ANGLE_LEFT:
      return "PHOTO_ANGLE_LEFT";
    case ScanReportScreen_PhotoAngle.PHOTO_ANGLE_RIGHT:
      return "PHOTO_ANGLE_RIGHT";
    case ScanReportScreen_PhotoAngle.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ScanReportScreen_IssuesByIdEntry {
  key: string;
  value: ScanReportScreen_Issue | undefined;
}

/**
 * Scan Report screen tab. Every tab might have several issues.
 * For example, `All` tab should contain all of the issues from the scan report.
 */
export interface ScanReportScreen_Tab {
  id: string;
  /** The ids of the issues from `issues_by_id` map. */
  issues_ids: string[];
  title: string;
  /**
   * The image that is displayed in the Scan Report screen tab.
   * The image is optional. For example, `All` tab doesn't have an image.
   */
  image: string;
  title_color: Color | undefined;
  background_color: Color | undefined;
  image_color: Color | undefined;
}

/** Issue that is displayed in the Scan Report screen tab. */
export interface ScanReportScreen_Issue {
  /** Previously `Issue.visualization` */
  frontal_visualization: IssueSubtypeVisualization | undefined;
  left_visualization: IssueSubtypeVisualization | undefined;
  right_visualization:
    | IssueSubtypeVisualization
    | undefined;
  /**
   * Use `Tab.title` instead.
   *
   * @deprecated
   */
  DEPRECATED_name: string;
  /**
   * Use `Tab.image` instead.
   *
   * @deprecated
   */
  DEPRECATED_image: string;
  /**
   * Use `Tab.image_color` instead.
   *
   * @deprecated
   */
  DEPRECATED_image_color: string;
  /**
   * Use `Tab.title_color` instead.
   *
   * @deprecated
   */
  DEPRECATED_title_color: string;
  /**
   * Use `Tab.background_color` instead.
   *
   * @deprecated
   */
  DEPRECATED_background_color: string;
  /**
   * Deprecated
   *
   * @deprecated
   */
  DEPRECATED_shape: ScanReportScreen_Issue_Shape;
}

/** @deprecated */
export enum ScanReportScreen_Issue_Shape {
  NONE = "NONE",
  SPOT = "SPOT",
  LINE = "LINE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function scanReportScreen_Issue_ShapeFromJSON(object: any): ScanReportScreen_Issue_Shape {
  switch (object) {
    case 0:
    case "NONE":
      return ScanReportScreen_Issue_Shape.NONE;
    case 1:
    case "SPOT":
      return ScanReportScreen_Issue_Shape.SPOT;
    case 2:
    case "LINE":
      return ScanReportScreen_Issue_Shape.LINE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ScanReportScreen_Issue_Shape.UNRECOGNIZED;
  }
}

export function scanReportScreen_Issue_ShapeToJSON(object: ScanReportScreen_Issue_Shape): string {
  switch (object) {
    case ScanReportScreen_Issue_Shape.NONE:
      return "NONE";
    case ScanReportScreen_Issue_Shape.SPOT:
      return "SPOT";
    case ScanReportScreen_Issue_Shape.LINE:
      return "LINE";
    case ScanReportScreen_Issue_Shape.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface XYPoint {
  x: number;
  y: number;
}

export interface NormalizedXYPoint {
  x: number;
  y: number;
}

export interface NormalizedRect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface Polygon {
  points: XYPoint[];
  normalized_points: NormalizedXYPoint[];
  hex_color: string;
  area: number;
}

export interface Polygons {
  polygons: Polygon[];
}

export interface BinaryMask {
  mask: string;
  hex_color: string;
  render_mode: BinaryMask_RenderMode;
  mask_rect: NormalizedRect | undefined;
}

export enum BinaryMask_RenderMode {
  /** NONE - Equal RAW_IMAGE */
  NONE = "NONE",
  RAW_IMAGE = "RAW_IMAGE",
  ANIMATED_HEATMAP = "ANIMATED_HEATMAP",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function binaryMask_RenderModeFromJSON(object: any): BinaryMask_RenderMode {
  switch (object) {
    case 0:
    case "NONE":
      return BinaryMask_RenderMode.NONE;
    case 1:
    case "RAW_IMAGE":
      return BinaryMask_RenderMode.RAW_IMAGE;
    case 2:
    case "ANIMATED_HEATMAP":
      return BinaryMask_RenderMode.ANIMATED_HEATMAP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BinaryMask_RenderMode.UNRECOGNIZED;
  }
}

export function binaryMask_RenderModeToJSON(object: BinaryMask_RenderMode): string {
  switch (object) {
    case BinaryMask_RenderMode.NONE:
      return "NONE";
    case BinaryMask_RenderMode.RAW_IMAGE:
      return "RAW_IMAGE";
    case BinaryMask_RenderMode.ANIMATED_HEATMAP:
      return "ANIMATED_HEATMAP";
    case BinaryMask_RenderMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface BinaryMasks {
  binary_masks: BinaryMask[];
}

export interface IssueSubtypeVisualization {
  id: string;
  visualization?: { $case: "polygons"; polygons: Polygons } | { $case: "binary_masks"; binary_masks: BinaryMasks };
}

function createBaseSummaryListItem(): SummaryListItem {
  return { image: "", title: "", description: "", badge: "" };
}

export const SummaryListItem = {
  fromJSON(object: any): SummaryListItem {
    return {
      image: isSet(object.image) ? String(object.image) : "",
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
      badge: isSet(object.badge) ? String(object.badge) : "",
    };
  },

  toJSON(message: SummaryListItem): unknown {
    const obj: any = {};
    message.image !== undefined && (obj.image = message.image);
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    message.badge !== undefined && (obj.badge = message.badge);
    return obj;
  },

  create<I extends Exact<DeepPartial<SummaryListItem>, I>>(base?: I): SummaryListItem {
    return SummaryListItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SummaryListItem>, I>>(object: I): SummaryListItem {
    const message = createBaseSummaryListItem();
    message.image = object.image ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.badge = object.badge ?? "";
    return message;
  },
};

function createBaseWebScanSkinIDScreen(): WebScanSkinIDScreen {
  return {
    title: undefined,
    subtitle: undefined,
    summary: undefined,
    footer_button_title: "",
    footer_button_quiz_id: "",
  };
}

export const WebScanSkinIDScreen = {
  fromJSON(object: any): WebScanSkinIDScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      subtitle: isSet(object.subtitle) ? AttributedString.fromJSON(object.subtitle) : undefined,
      summary: isSet(object.summary) ? WebScanSkinIDScreen_Summary.fromJSON(object.summary) : undefined,
      footer_button_title: isSet(object.footer_button_title) ? String(object.footer_button_title) : "",
      footer_button_quiz_id: isSet(object.footer_button_quiz_id) ? String(object.footer_button_quiz_id) : "",
    };
  },

  toJSON(message: WebScanSkinIDScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.subtitle !== undefined &&
      (obj.subtitle = message.subtitle ? AttributedString.toJSON(message.subtitle) : undefined);
    message.summary !== undefined &&
      (obj.summary = message.summary ? WebScanSkinIDScreen_Summary.toJSON(message.summary) : undefined);
    message.footer_button_title !== undefined && (obj.footer_button_title = message.footer_button_title);
    message.footer_button_quiz_id !== undefined && (obj.footer_button_quiz_id = message.footer_button_quiz_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebScanSkinIDScreen>, I>>(base?: I): WebScanSkinIDScreen {
    return WebScanSkinIDScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebScanSkinIDScreen>, I>>(object: I): WebScanSkinIDScreen {
    const message = createBaseWebScanSkinIDScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.subtitle = (object.subtitle !== undefined && object.subtitle !== null)
      ? AttributedString.fromPartial(object.subtitle)
      : undefined;
    message.summary = (object.summary !== undefined && object.summary !== null)
      ? WebScanSkinIDScreen_Summary.fromPartial(object.summary)
      : undefined;
    message.footer_button_title = object.footer_button_title ?? "";
    message.footer_button_quiz_id = object.footer_button_quiz_id ?? "";
    return message;
  },
};

function createBaseWebScanSkinIDScreen_Summary(): WebScanSkinIDScreen_Summary {
  return { items: [] };
}

export const WebScanSkinIDScreen_Summary = {
  fromJSON(object: any): WebScanSkinIDScreen_Summary {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => WebScanSkinIDScreen_Summary_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: WebScanSkinIDScreen_Summary): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? WebScanSkinIDScreen_Summary_Item.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebScanSkinIDScreen_Summary>, I>>(base?: I): WebScanSkinIDScreen_Summary {
    return WebScanSkinIDScreen_Summary.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebScanSkinIDScreen_Summary>, I>>(object: I): WebScanSkinIDScreen_Summary {
    const message = createBaseWebScanSkinIDScreen_Summary();
    message.items = object.items?.map((e) => WebScanSkinIDScreen_Summary_Item.fromPartial(e)) || [];
    return message;
  },
};

function createBaseWebScanSkinIDScreen_Summary_Item(): WebScanSkinIDScreen_Summary_Item {
  return { id: "", title: "", value: undefined };
}

export const WebScanSkinIDScreen_Summary_Item = {
  fromJSON(object: any): WebScanSkinIDScreen_Summary_Item {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? String(object.title) : "",
      value: isSet(object.value) ? WebScanSkinIDScreen_Summary_Item_Value.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: WebScanSkinIDScreen_Summary_Item): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.title !== undefined && (obj.title = message.title);
    message.value !== undefined &&
      (obj.value = message.value ? WebScanSkinIDScreen_Summary_Item_Value.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebScanSkinIDScreen_Summary_Item>, I>>(
    base?: I,
  ): WebScanSkinIDScreen_Summary_Item {
    return WebScanSkinIDScreen_Summary_Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebScanSkinIDScreen_Summary_Item>, I>>(
    object: I,
  ): WebScanSkinIDScreen_Summary_Item {
    const message = createBaseWebScanSkinIDScreen_Summary_Item();
    message.id = object.id ?? "";
    message.title = object.title ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? WebScanSkinIDScreen_Summary_Item_Value.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseWebScanSkinIDScreen_Summary_Item_Value(): WebScanSkinIDScreen_Summary_Item_Value {
  return { value: undefined };
}

export const WebScanSkinIDScreen_Summary_Item_Value = {
  fromJSON(object: any): WebScanSkinIDScreen_Summary_Item_Value {
    return {
      value: isSet(object.answer)
        ? { $case: "answer", answer: WebScanSkinIDScreen_Summary_Item_Value_Answer.fromJSON(object.answer) }
        : isSet(object.web_scan)
        ? { $case: "web_scan", web_scan: WebScanSkinIDScreen_Summary_Item_Value_WebScan.fromJSON(object.web_scan) }
        : undefined,
    };
  },

  toJSON(message: WebScanSkinIDScreen_Summary_Item_Value): unknown {
    const obj: any = {};
    message.value?.$case === "answer" && (obj.answer = message.value?.answer
      ? WebScanSkinIDScreen_Summary_Item_Value_Answer.toJSON(message.value?.answer)
      : undefined);
    message.value?.$case === "web_scan" && (obj.web_scan = message.value?.web_scan
      ? WebScanSkinIDScreen_Summary_Item_Value_WebScan.toJSON(message.value?.web_scan)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebScanSkinIDScreen_Summary_Item_Value>, I>>(
    base?: I,
  ): WebScanSkinIDScreen_Summary_Item_Value {
    return WebScanSkinIDScreen_Summary_Item_Value.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebScanSkinIDScreen_Summary_Item_Value>, I>>(
    object: I,
  ): WebScanSkinIDScreen_Summary_Item_Value {
    const message = createBaseWebScanSkinIDScreen_Summary_Item_Value();
    if (object.value?.$case === "answer" && object.value?.answer !== undefined && object.value?.answer !== null) {
      message.value = {
        $case: "answer",
        answer: WebScanSkinIDScreen_Summary_Item_Value_Answer.fromPartial(object.value.answer),
      };
    }
    if (object.value?.$case === "web_scan" && object.value?.web_scan !== undefined && object.value?.web_scan !== null) {
      message.value = {
        $case: "web_scan",
        web_scan: WebScanSkinIDScreen_Summary_Item_Value_WebScan.fromPartial(object.value.web_scan),
      };
    }
    return message;
  },
};

function createBaseWebScanSkinIDScreen_Summary_Item_Value_Answer(): WebScanSkinIDScreen_Summary_Item_Value_Answer {
  return { answer: "", is_secondary: false, quiz_id: "" };
}

export const WebScanSkinIDScreen_Summary_Item_Value_Answer = {
  fromJSON(object: any): WebScanSkinIDScreen_Summary_Item_Value_Answer {
    return {
      answer: isSet(object.answer) ? String(object.answer) : "",
      is_secondary: isSet(object.is_secondary) ? Boolean(object.is_secondary) : false,
      quiz_id: isSet(object.quiz_id) ? String(object.quiz_id) : "",
    };
  },

  toJSON(message: WebScanSkinIDScreen_Summary_Item_Value_Answer): unknown {
    const obj: any = {};
    message.answer !== undefined && (obj.answer = message.answer);
    message.is_secondary !== undefined && (obj.is_secondary = message.is_secondary);
    message.quiz_id !== undefined && (obj.quiz_id = message.quiz_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebScanSkinIDScreen_Summary_Item_Value_Answer>, I>>(
    base?: I,
  ): WebScanSkinIDScreen_Summary_Item_Value_Answer {
    return WebScanSkinIDScreen_Summary_Item_Value_Answer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebScanSkinIDScreen_Summary_Item_Value_Answer>, I>>(
    object: I,
  ): WebScanSkinIDScreen_Summary_Item_Value_Answer {
    const message = createBaseWebScanSkinIDScreen_Summary_Item_Value_Answer();
    message.answer = object.answer ?? "";
    message.is_secondary = object.is_secondary ?? false;
    message.quiz_id = object.quiz_id ?? "";
    return message;
  },
};

function createBaseWebScanSkinIDScreen_Summary_Item_Value_WebScan(): WebScanSkinIDScreen_Summary_Item_Value_WebScan {
  return { content: undefined };
}

export const WebScanSkinIDScreen_Summary_Item_Value_WebScan = {
  fromJSON(object: any): WebScanSkinIDScreen_Summary_Item_Value_WebScan {
    return {
      content: isSet(object.resource)
        ? { $case: "resource", resource: String(object.resource) }
        : isSet(object.scan_id)
        ? { $case: "scan_id", scan_id: String(object.scan_id) }
        : undefined,
    };
  },

  toJSON(message: WebScanSkinIDScreen_Summary_Item_Value_WebScan): unknown {
    const obj: any = {};
    message.content?.$case === "resource" && (obj.resource = message.content?.resource);
    message.content?.$case === "scan_id" && (obj.scan_id = message.content?.scan_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebScanSkinIDScreen_Summary_Item_Value_WebScan>, I>>(
    base?: I,
  ): WebScanSkinIDScreen_Summary_Item_Value_WebScan {
    return WebScanSkinIDScreen_Summary_Item_Value_WebScan.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebScanSkinIDScreen_Summary_Item_Value_WebScan>, I>>(
    object: I,
  ): WebScanSkinIDScreen_Summary_Item_Value_WebScan {
    const message = createBaseWebScanSkinIDScreen_Summary_Item_Value_WebScan();
    if (
      object.content?.$case === "resource" &&
      object.content?.resource !== undefined &&
      object.content?.resource !== null
    ) {
      message.content = { $case: "resource", resource: object.content.resource };
    }
    if (
      object.content?.$case === "scan_id" && object.content?.scan_id !== undefined && object.content?.scan_id !== null
    ) {
      message.content = { $case: "scan_id", scan_id: object.content.scan_id };
    }
    return message;
  },
};

function createBaseSkinIDScreen(): SkinIDScreen {
  return {
    scan_report_id: "",
    title: undefined,
    summary_items: [],
    visualizations: [],
    view_report_button_title: "",
    bottom_button_title: "",
  };
}

export const SkinIDScreen = {
  fromJSON(object: any): SkinIDScreen {
    return {
      scan_report_id: isSet(object.scan_report_id) ? String(object.scan_report_id) : "",
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      summary_items: Array.isArray(object?.summary_items)
        ? object.summary_items.map((e: any) => SummaryListItem.fromJSON(e))
        : [],
      visualizations: Array.isArray(object?.visualizations)
        ? object.visualizations.map((e: any) => IssueSubtypeVisualization.fromJSON(e))
        : [],
      view_report_button_title: isSet(object.view_report_button_title) ? String(object.view_report_button_title) : "",
      bottom_button_title: isSet(object.bottom_button_title) ? String(object.bottom_button_title) : "",
    };
  },

  toJSON(message: SkinIDScreen): unknown {
    const obj: any = {};
    message.scan_report_id !== undefined && (obj.scan_report_id = message.scan_report_id);
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    if (message.summary_items) {
      obj.summary_items = message.summary_items.map((e) => e ? SummaryListItem.toJSON(e) : undefined);
    } else {
      obj.summary_items = [];
    }
    if (message.visualizations) {
      obj.visualizations = message.visualizations.map((e) => e ? IssueSubtypeVisualization.toJSON(e) : undefined);
    } else {
      obj.visualizations = [];
    }
    message.view_report_button_title !== undefined && (obj.view_report_button_title = message.view_report_button_title);
    message.bottom_button_title !== undefined && (obj.bottom_button_title = message.bottom_button_title);
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinIDScreen>, I>>(base?: I): SkinIDScreen {
    return SkinIDScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinIDScreen>, I>>(object: I): SkinIDScreen {
    const message = createBaseSkinIDScreen();
    message.scan_report_id = object.scan_report_id ?? "";
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.summary_items = object.summary_items?.map((e) => SummaryListItem.fromPartial(e)) || [];
    message.visualizations = object.visualizations?.map((e) => IssueSubtypeVisualization.fromPartial(e)) || [];
    message.view_report_button_title = object.view_report_button_title ?? "";
    message.bottom_button_title = object.bottom_button_title ?? "";
    return message;
  },
};

function createBaseScanReportScreen(): ScanReportScreen {
  return {
    scan_report_id: "",
    date: "",
    sub_title: "",
    title: "",
    displayed_photo_angles: [],
    tabs: [],
    selected_tab_id: "",
    issues_by_id: {},
    DEPRECATED_frontal_issues: [],
    DEPRECATED_left_issues: [],
    DEPRECATED_right_issues: [],
  };
}

export const ScanReportScreen = {
  fromJSON(object: any): ScanReportScreen {
    return {
      scan_report_id: isSet(object.scan_report_id) ? String(object.scan_report_id) : "",
      date: isSet(object.date) ? String(object.date) : "",
      sub_title: isSet(object.sub_title) ? String(object.sub_title) : "",
      title: isSet(object.title) ? String(object.title) : "",
      displayed_photo_angles: Array.isArray(object?.displayed_photo_angles)
        ? object.displayed_photo_angles.map((e: any) => scanReportScreen_PhotoAngleFromJSON(e))
        : [],
      tabs: Array.isArray(object?.tabs)
        ? object.tabs.map((e: any) => ScanReportScreen_Tab.fromJSON(e))
        : [],
      selected_tab_id: isSet(object.selected_tab_id) ? String(object.selected_tab_id) : "",
      issues_by_id: isObject(object.issues_by_id)
        ? Object.entries(object.issues_by_id).reduce<{ [key: string]: ScanReportScreen_Issue }>((acc, [key, value]) => {
          acc[key] = ScanReportScreen_Issue.fromJSON(value);
          return acc;
        }, {})
        : {},
      DEPRECATED_frontal_issues: Array.isArray(object?.DEPRECATED_frontal_issues)
        ? object.DEPRECATED_frontal_issues.map((e: any) => ScanReportScreen_Issue.fromJSON(e))
        : [],
      DEPRECATED_left_issues: Array.isArray(object?.DEPRECATED_left_issues)
        ? object.DEPRECATED_left_issues.map((e: any) => ScanReportScreen_Issue.fromJSON(e))
        : [],
      DEPRECATED_right_issues: Array.isArray(object?.DEPRECATED_right_issues)
        ? object.DEPRECATED_right_issues.map((e: any) => ScanReportScreen_Issue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ScanReportScreen): unknown {
    const obj: any = {};
    message.scan_report_id !== undefined && (obj.scan_report_id = message.scan_report_id);
    message.date !== undefined && (obj.date = message.date);
    message.sub_title !== undefined && (obj.sub_title = message.sub_title);
    message.title !== undefined && (obj.title = message.title);
    if (message.displayed_photo_angles) {
      obj.displayed_photo_angles = message.displayed_photo_angles.map((e) => scanReportScreen_PhotoAngleToJSON(e));
    } else {
      obj.displayed_photo_angles = [];
    }
    if (message.tabs) {
      obj.tabs = message.tabs.map((e) => e ? ScanReportScreen_Tab.toJSON(e) : undefined);
    } else {
      obj.tabs = [];
    }
    message.selected_tab_id !== undefined && (obj.selected_tab_id = message.selected_tab_id);
    obj.issues_by_id = {};
    if (message.issues_by_id) {
      Object.entries(message.issues_by_id).forEach(([k, v]) => {
        obj.issues_by_id[k] = ScanReportScreen_Issue.toJSON(v);
      });
    }
    if (message.DEPRECATED_frontal_issues) {
      obj.DEPRECATED_frontal_issues = message.DEPRECATED_frontal_issues.map((e) =>
        e ? ScanReportScreen_Issue.toJSON(e) : undefined
      );
    } else {
      obj.DEPRECATED_frontal_issues = [];
    }
    if (message.DEPRECATED_left_issues) {
      obj.DEPRECATED_left_issues = message.DEPRECATED_left_issues.map((e) =>
        e ? ScanReportScreen_Issue.toJSON(e) : undefined
      );
    } else {
      obj.DEPRECATED_left_issues = [];
    }
    if (message.DEPRECATED_right_issues) {
      obj.DEPRECATED_right_issues = message.DEPRECATED_right_issues.map((e) =>
        e ? ScanReportScreen_Issue.toJSON(e) : undefined
      );
    } else {
      obj.DEPRECATED_right_issues = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanReportScreen>, I>>(base?: I): ScanReportScreen {
    return ScanReportScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanReportScreen>, I>>(object: I): ScanReportScreen {
    const message = createBaseScanReportScreen();
    message.scan_report_id = object.scan_report_id ?? "";
    message.date = object.date ?? "";
    message.sub_title = object.sub_title ?? "";
    message.title = object.title ?? "";
    message.displayed_photo_angles = object.displayed_photo_angles?.map((e) => e) || [];
    message.tabs = object.tabs?.map((e) => ScanReportScreen_Tab.fromPartial(e)) || [];
    message.selected_tab_id = object.selected_tab_id ?? "";
    message.issues_by_id = Object.entries(object.issues_by_id ?? {}).reduce<{ [key: string]: ScanReportScreen_Issue }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = ScanReportScreen_Issue.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.DEPRECATED_frontal_issues =
      object.DEPRECATED_frontal_issues?.map((e) => ScanReportScreen_Issue.fromPartial(e)) || [];
    message.DEPRECATED_left_issues = object.DEPRECATED_left_issues?.map((e) => ScanReportScreen_Issue.fromPartial(e)) ||
      [];
    message.DEPRECATED_right_issues =
      object.DEPRECATED_right_issues?.map((e) => ScanReportScreen_Issue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseScanReportScreen_IssuesByIdEntry(): ScanReportScreen_IssuesByIdEntry {
  return { key: "", value: undefined };
}

export const ScanReportScreen_IssuesByIdEntry = {
  fromJSON(object: any): ScanReportScreen_IssuesByIdEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? ScanReportScreen_Issue.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ScanReportScreen_IssuesByIdEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? ScanReportScreen_Issue.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanReportScreen_IssuesByIdEntry>, I>>(
    base?: I,
  ): ScanReportScreen_IssuesByIdEntry {
    return ScanReportScreen_IssuesByIdEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanReportScreen_IssuesByIdEntry>, I>>(
    object: I,
  ): ScanReportScreen_IssuesByIdEntry {
    const message = createBaseScanReportScreen_IssuesByIdEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ScanReportScreen_Issue.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseScanReportScreen_Tab(): ScanReportScreen_Tab {
  return {
    id: "",
    issues_ids: [],
    title: "",
    image: "",
    title_color: undefined,
    background_color: undefined,
    image_color: undefined,
  };
}

export const ScanReportScreen_Tab = {
  fromJSON(object: any): ScanReportScreen_Tab {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      issues_ids: Array.isArray(object?.issues_ids) ? object.issues_ids.map((e: any) => String(e)) : [],
      title: isSet(object.title) ? String(object.title) : "",
      image: isSet(object.image) ? String(object.image) : "",
      title_color: isSet(object.title_color) ? Color.fromJSON(object.title_color) : undefined,
      background_color: isSet(object.background_color) ? Color.fromJSON(object.background_color) : undefined,
      image_color: isSet(object.image_color) ? Color.fromJSON(object.image_color) : undefined,
    };
  },

  toJSON(message: ScanReportScreen_Tab): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    if (message.issues_ids) {
      obj.issues_ids = message.issues_ids.map((e) => e);
    } else {
      obj.issues_ids = [];
    }
    message.title !== undefined && (obj.title = message.title);
    message.image !== undefined && (obj.image = message.image);
    message.title_color !== undefined &&
      (obj.title_color = message.title_color ? Color.toJSON(message.title_color) : undefined);
    message.background_color !== undefined &&
      (obj.background_color = message.background_color ? Color.toJSON(message.background_color) : undefined);
    message.image_color !== undefined &&
      (obj.image_color = message.image_color ? Color.toJSON(message.image_color) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanReportScreen_Tab>, I>>(base?: I): ScanReportScreen_Tab {
    return ScanReportScreen_Tab.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanReportScreen_Tab>, I>>(object: I): ScanReportScreen_Tab {
    const message = createBaseScanReportScreen_Tab();
    message.id = object.id ?? "";
    message.issues_ids = object.issues_ids?.map((e) => e) || [];
    message.title = object.title ?? "";
    message.image = object.image ?? "";
    message.title_color = (object.title_color !== undefined && object.title_color !== null)
      ? Color.fromPartial(object.title_color)
      : undefined;
    message.background_color = (object.background_color !== undefined && object.background_color !== null)
      ? Color.fromPartial(object.background_color)
      : undefined;
    message.image_color = (object.image_color !== undefined && object.image_color !== null)
      ? Color.fromPartial(object.image_color)
      : undefined;
    return message;
  },
};

function createBaseScanReportScreen_Issue(): ScanReportScreen_Issue {
  return {
    frontal_visualization: undefined,
    left_visualization: undefined,
    right_visualization: undefined,
    DEPRECATED_name: "",
    DEPRECATED_image: "",
    DEPRECATED_image_color: "",
    DEPRECATED_title_color: "",
    DEPRECATED_background_color: "",
    DEPRECATED_shape: ScanReportScreen_Issue_Shape.NONE,
  };
}

export const ScanReportScreen_Issue = {
  fromJSON(object: any): ScanReportScreen_Issue {
    return {
      frontal_visualization: isSet(object.frontal_visualization)
        ? IssueSubtypeVisualization.fromJSON(object.frontal_visualization)
        : undefined,
      left_visualization: isSet(object.left_visualization)
        ? IssueSubtypeVisualization.fromJSON(object.left_visualization)
        : undefined,
      right_visualization: isSet(object.right_visualization)
        ? IssueSubtypeVisualization.fromJSON(object.right_visualization)
        : undefined,
      DEPRECATED_name: isSet(object.DEPRECATED_name) ? String(object.DEPRECATED_name) : "",
      DEPRECATED_image: isSet(object.DEPRECATED_image) ? String(object.DEPRECATED_image) : "",
      DEPRECATED_image_color: isSet(object.DEPRECATED_image_color) ? String(object.DEPRECATED_image_color) : "",
      DEPRECATED_title_color: isSet(object.DEPRECATED_title_color) ? String(object.DEPRECATED_title_color) : "",
      DEPRECATED_background_color: isSet(object.DEPRECATED_background_color)
        ? String(object.DEPRECATED_background_color)
        : "",
      DEPRECATED_shape: isSet(object.DEPRECATED_shape)
        ? scanReportScreen_Issue_ShapeFromJSON(object.DEPRECATED_shape)
        : ScanReportScreen_Issue_Shape.NONE,
    };
  },

  toJSON(message: ScanReportScreen_Issue): unknown {
    const obj: any = {};
    message.frontal_visualization !== undefined && (obj.frontal_visualization = message.frontal_visualization
      ? IssueSubtypeVisualization.toJSON(message.frontal_visualization)
      : undefined);
    message.left_visualization !== undefined && (obj.left_visualization = message.left_visualization
      ? IssueSubtypeVisualization.toJSON(message.left_visualization)
      : undefined);
    message.right_visualization !== undefined && (obj.right_visualization = message.right_visualization
      ? IssueSubtypeVisualization.toJSON(message.right_visualization)
      : undefined);
    message.DEPRECATED_name !== undefined && (obj.DEPRECATED_name = message.DEPRECATED_name);
    message.DEPRECATED_image !== undefined && (obj.DEPRECATED_image = message.DEPRECATED_image);
    message.DEPRECATED_image_color !== undefined && (obj.DEPRECATED_image_color = message.DEPRECATED_image_color);
    message.DEPRECATED_title_color !== undefined && (obj.DEPRECATED_title_color = message.DEPRECATED_title_color);
    message.DEPRECATED_background_color !== undefined &&
      (obj.DEPRECATED_background_color = message.DEPRECATED_background_color);
    message.DEPRECATED_shape !== undefined &&
      (obj.DEPRECATED_shape = scanReportScreen_Issue_ShapeToJSON(message.DEPRECATED_shape));
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanReportScreen_Issue>, I>>(base?: I): ScanReportScreen_Issue {
    return ScanReportScreen_Issue.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanReportScreen_Issue>, I>>(object: I): ScanReportScreen_Issue {
    const message = createBaseScanReportScreen_Issue();
    message.frontal_visualization =
      (object.frontal_visualization !== undefined && object.frontal_visualization !== null)
        ? IssueSubtypeVisualization.fromPartial(object.frontal_visualization)
        : undefined;
    message.left_visualization = (object.left_visualization !== undefined && object.left_visualization !== null)
      ? IssueSubtypeVisualization.fromPartial(object.left_visualization)
      : undefined;
    message.right_visualization = (object.right_visualization !== undefined && object.right_visualization !== null)
      ? IssueSubtypeVisualization.fromPartial(object.right_visualization)
      : undefined;
    message.DEPRECATED_name = object.DEPRECATED_name ?? "";
    message.DEPRECATED_image = object.DEPRECATED_image ?? "";
    message.DEPRECATED_image_color = object.DEPRECATED_image_color ?? "";
    message.DEPRECATED_title_color = object.DEPRECATED_title_color ?? "";
    message.DEPRECATED_background_color = object.DEPRECATED_background_color ?? "";
    message.DEPRECATED_shape = object.DEPRECATED_shape ?? ScanReportScreen_Issue_Shape.NONE;
    return message;
  },
};

function createBaseXYPoint(): XYPoint {
  return { x: 0, y: 0 };
}

export const XYPoint = {
  fromJSON(object: any): XYPoint {
    return { x: isSet(object.x) ? Number(object.x) : 0, y: isSet(object.y) ? Number(object.y) : 0 };
  },

  toJSON(message: XYPoint): unknown {
    const obj: any = {};
    message.x !== undefined && (obj.x = Math.round(message.x));
    message.y !== undefined && (obj.y = Math.round(message.y));
    return obj;
  },

  create<I extends Exact<DeepPartial<XYPoint>, I>>(base?: I): XYPoint {
    return XYPoint.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<XYPoint>, I>>(object: I): XYPoint {
    const message = createBaseXYPoint();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    return message;
  },
};

function createBaseNormalizedXYPoint(): NormalizedXYPoint {
  return { x: 0, y: 0 };
}

export const NormalizedXYPoint = {
  fromJSON(object: any): NormalizedXYPoint {
    return { x: isSet(object.x) ? Number(object.x) : 0, y: isSet(object.y) ? Number(object.y) : 0 };
  },

  toJSON(message: NormalizedXYPoint): unknown {
    const obj: any = {};
    message.x !== undefined && (obj.x = message.x);
    message.y !== undefined && (obj.y = message.y);
    return obj;
  },

  create<I extends Exact<DeepPartial<NormalizedXYPoint>, I>>(base?: I): NormalizedXYPoint {
    return NormalizedXYPoint.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NormalizedXYPoint>, I>>(object: I): NormalizedXYPoint {
    const message = createBaseNormalizedXYPoint();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    return message;
  },
};

function createBaseNormalizedRect(): NormalizedRect {
  return { x: 0, y: 0, width: 0, height: 0 };
}

export const NormalizedRect = {
  fromJSON(object: any): NormalizedRect {
    return {
      x: isSet(object.x) ? Number(object.x) : 0,
      y: isSet(object.y) ? Number(object.y) : 0,
      width: isSet(object.width) ? Number(object.width) : 0,
      height: isSet(object.height) ? Number(object.height) : 0,
    };
  },

  toJSON(message: NormalizedRect): unknown {
    const obj: any = {};
    message.x !== undefined && (obj.x = message.x);
    message.y !== undefined && (obj.y = message.y);
    message.width !== undefined && (obj.width = message.width);
    message.height !== undefined && (obj.height = message.height);
    return obj;
  },

  create<I extends Exact<DeepPartial<NormalizedRect>, I>>(base?: I): NormalizedRect {
    return NormalizedRect.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NormalizedRect>, I>>(object: I): NormalizedRect {
    const message = createBaseNormalizedRect();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    message.width = object.width ?? 0;
    message.height = object.height ?? 0;
    return message;
  },
};

function createBasePolygon(): Polygon {
  return { points: [], normalized_points: [], hex_color: "", area: 0 };
}

export const Polygon = {
  fromJSON(object: any): Polygon {
    return {
      points: Array.isArray(object?.points) ? object.points.map((e: any) => XYPoint.fromJSON(e)) : [],
      normalized_points: Array.isArray(object?.normalized_points)
        ? object.normalized_points.map((e: any) => NormalizedXYPoint.fromJSON(e))
        : [],
      hex_color: isSet(object.hex_color) ? String(object.hex_color) : "",
      area: isSet(object.area) ? Number(object.area) : 0,
    };
  },

  toJSON(message: Polygon): unknown {
    const obj: any = {};
    if (message.points) {
      obj.points = message.points.map((e) => e ? XYPoint.toJSON(e) : undefined);
    } else {
      obj.points = [];
    }
    if (message.normalized_points) {
      obj.normalized_points = message.normalized_points.map((e) => e ? NormalizedXYPoint.toJSON(e) : undefined);
    } else {
      obj.normalized_points = [];
    }
    message.hex_color !== undefined && (obj.hex_color = message.hex_color);
    message.area !== undefined && (obj.area = message.area);
    return obj;
  },

  create<I extends Exact<DeepPartial<Polygon>, I>>(base?: I): Polygon {
    return Polygon.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Polygon>, I>>(object: I): Polygon {
    const message = createBasePolygon();
    message.points = object.points?.map((e) => XYPoint.fromPartial(e)) || [];
    message.normalized_points = object.normalized_points?.map((e) => NormalizedXYPoint.fromPartial(e)) || [];
    message.hex_color = object.hex_color ?? "";
    message.area = object.area ?? 0;
    return message;
  },
};

function createBasePolygons(): Polygons {
  return { polygons: [] };
}

export const Polygons = {
  fromJSON(object: any): Polygons {
    return { polygons: Array.isArray(object?.polygons) ? object.polygons.map((e: any) => Polygon.fromJSON(e)) : [] };
  },

  toJSON(message: Polygons): unknown {
    const obj: any = {};
    if (message.polygons) {
      obj.polygons = message.polygons.map((e) => e ? Polygon.toJSON(e) : undefined);
    } else {
      obj.polygons = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Polygons>, I>>(base?: I): Polygons {
    return Polygons.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Polygons>, I>>(object: I): Polygons {
    const message = createBasePolygons();
    message.polygons = object.polygons?.map((e) => Polygon.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBinaryMask(): BinaryMask {
  return { mask: "", hex_color: "", render_mode: BinaryMask_RenderMode.NONE, mask_rect: undefined };
}

export const BinaryMask = {
  fromJSON(object: any): BinaryMask {
    return {
      mask: isSet(object.mask) ? String(object.mask) : "",
      hex_color: isSet(object.hex_color) ? String(object.hex_color) : "",
      render_mode: isSet(object.render_mode)
        ? binaryMask_RenderModeFromJSON(object.render_mode)
        : BinaryMask_RenderMode.NONE,
      mask_rect: isSet(object.mask_rect) ? NormalizedRect.fromJSON(object.mask_rect) : undefined,
    };
  },

  toJSON(message: BinaryMask): unknown {
    const obj: any = {};
    message.mask !== undefined && (obj.mask = message.mask);
    message.hex_color !== undefined && (obj.hex_color = message.hex_color);
    message.render_mode !== undefined && (obj.render_mode = binaryMask_RenderModeToJSON(message.render_mode));
    message.mask_rect !== undefined &&
      (obj.mask_rect = message.mask_rect ? NormalizedRect.toJSON(message.mask_rect) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<BinaryMask>, I>>(base?: I): BinaryMask {
    return BinaryMask.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BinaryMask>, I>>(object: I): BinaryMask {
    const message = createBaseBinaryMask();
    message.mask = object.mask ?? "";
    message.hex_color = object.hex_color ?? "";
    message.render_mode = object.render_mode ?? BinaryMask_RenderMode.NONE;
    message.mask_rect = (object.mask_rect !== undefined && object.mask_rect !== null)
      ? NormalizedRect.fromPartial(object.mask_rect)
      : undefined;
    return message;
  },
};

function createBaseBinaryMasks(): BinaryMasks {
  return { binary_masks: [] };
}

export const BinaryMasks = {
  fromJSON(object: any): BinaryMasks {
    return {
      binary_masks: Array.isArray(object?.binary_masks)
        ? object.binary_masks.map((e: any) => BinaryMask.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BinaryMasks): unknown {
    const obj: any = {};
    if (message.binary_masks) {
      obj.binary_masks = message.binary_masks.map((e) => e ? BinaryMask.toJSON(e) : undefined);
    } else {
      obj.binary_masks = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BinaryMasks>, I>>(base?: I): BinaryMasks {
    return BinaryMasks.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BinaryMasks>, I>>(object: I): BinaryMasks {
    const message = createBaseBinaryMasks();
    message.binary_masks = object.binary_masks?.map((e) => BinaryMask.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIssueSubtypeVisualization(): IssueSubtypeVisualization {
  return { id: "", visualization: undefined };
}

export const IssueSubtypeVisualization = {
  fromJSON(object: any): IssueSubtypeVisualization {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      visualization: isSet(object.polygons)
        ? { $case: "polygons", polygons: Polygons.fromJSON(object.polygons) }
        : isSet(object.binary_masks)
        ? { $case: "binary_masks", binary_masks: BinaryMasks.fromJSON(object.binary_masks) }
        : undefined,
    };
  },

  toJSON(message: IssueSubtypeVisualization): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.visualization?.$case === "polygons" &&
      (obj.polygons = message.visualization?.polygons ? Polygons.toJSON(message.visualization?.polygons) : undefined);
    message.visualization?.$case === "binary_masks" && (obj.binary_masks = message.visualization?.binary_masks
      ? BinaryMasks.toJSON(message.visualization?.binary_masks)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueSubtypeVisualization>, I>>(base?: I): IssueSubtypeVisualization {
    return IssueSubtypeVisualization.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IssueSubtypeVisualization>, I>>(object: I): IssueSubtypeVisualization {
    const message = createBaseIssueSubtypeVisualization();
    message.id = object.id ?? "";
    if (
      object.visualization?.$case === "polygons" &&
      object.visualization?.polygons !== undefined &&
      object.visualization?.polygons !== null
    ) {
      message.visualization = { $case: "polygons", polygons: Polygons.fromPartial(object.visualization.polygons) };
    }
    if (
      object.visualization?.$case === "binary_masks" &&
      object.visualization?.binary_masks !== undefined &&
      object.visualization?.binary_masks !== null
    ) {
      message.visualization = {
        $case: "binary_masks",
        binary_masks: BinaryMasks.fromPartial(object.visualization.binary_masks),
      };
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
